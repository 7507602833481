<template>
  <div class="TaskJob">
    <el-row>
      <el-button-group class="mr_20">
        <el-button type="primary" :disabled="disabled" @click="handleClick(null, 'subway')">广州地铁对接配置</el-button>
      </el-button-group>

      <el-select class="host mr_20" v-model="hostId" filterable placeholder="请选择主机查看任务">
        <el-option v-for="item in hostList" :key="item.pkHostId" :label="'当前主机:' + item.hostNumber" :value="item.pkHostId">
          <p>
            {{ item.hostNumber }}
            <span style="color: green; float: right">{{ item.company.companyName }}</span>
          </p>
        </el-option>
      </el-select>

      <el-input v-model="projectName" class="mr_20">
        <template slot="prepend"><span>工程名称</span></template>
        <el-button slot="append" icon="el-icon-search" @click="findAllProject">查询 </el-button>
      </el-input>
    </el-row>
    <MyTable
      ref="myTable"
      :dataSource="dataSource"
      :columns="columns"
      border
      :loading="loading"
      :total="total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      :highlightCurrentRow="true"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleClick="handleClick"
      @handleSelectItem="handleSelectItem"
    ></MyTable>

    <el-dialog class="subway" title="对接配置" center :visible.sync="dialogVisibleSubway" :before-close="handleClose">
      <SubwayDocking v-if="dialogVisibleSubway" :pkTaskId="pkTaskId"></SubwayDocking>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'

import SubwayDocking from './SubwayDocking'
import { findHostByUnit, findAllHost, findHostByUserIdHost, findHostByUserIdUser } from '@/api/Host.js'
import { findTaskByHostId } from '@/api/Task.js'

const columns = [
  {
    prop: 'projectNo',
    label: '工程编号',
    type: 'text',
    align: 'center',
    sortable: true,
  },
  {
    prop: 'projectName',
    label: '工程名称',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'projectAddress',
    label: '工程地址',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'hostNumber',
    label: '所属主机',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'safeState',
    label: '安全转态',
    width: 150,
    type: 'text',
    align: 'center',
  },
  {
    prop: 'runningState',
    label: '运行状态',
    width: 150,
    type: 'text',
    align: 'center',
  },
]
const dataSource = []
export default {
  name: 'Job',
  components: { MyTable, SubwayDocking },
  data() {
    return {
      projectName: null,
      currentPage: 0,
      pageSize: 10,
      total: 30,
      loading: true,
      dataSource,
      columns,
      stateNum: null,
      stateId: 0,
      pkTaskId: null,
      hostId: null,
      hostList: [],
      // 弹窗

      dialogVisibleSubway: false,

      disabled: true,
    }
  },
  watch: {
    hostId(v) {
      console.log('new', v)
      // console.log(this.$refs.myTable)

      this.$refs.myTable.setCurrent()
      // this.$refs.myTable.setCurrentRow([])

      this.handleSelectItem(null)

      this.findAllProject()
    },
  },

  methods: {
    /*
      根据不同的用户 返回不同的数据
      admin     查询所有主机
      系统管理员 查询单位所有主机
      主机管理员 查询用户拥有的所有主机
      普通用户   查询用户拥有的所有主机
    */
    async findHostByUser() {
      this.loading = true
      if (this.$store.state.power === 4) {
        return await findAllHost(0, 99999)
      }
      if (this.$store.state.power === 3) {
        return await findHostByUnit(this.$store.state.unitId, 0, 99999)
      }
      if (this.$store.state.power === 2) {
        return await findHostByUserIdHost(this.$store.state.userId, 0, 99999)
      }
      if (this.$store.state.power === 1) {
        return await findHostByUserIdUser(this.$store.state.userId)
      }
    },
    async findAllHost() {
      const { data: res } = await this.findHostByUser()

      this.hostList = res.data.list || res.data
      this.hostList.forEach((item) => {
        item.companyName = item.company.companyName
      })
      console.log('this.$route==>', this.$route.params.hostId)
      this.hostId = this.$route.params.hostId ? this.$route.params.hostId : this.hostList.length > 0 ? this.hostList[this.hostList.length - 1].pkHostId : null
    },
    async findAllProject() {
      this.loading = true

      if (!this.hostId) {
        //如果不存在主机ID时，不需要调用API查询主机下任务
        setTimeout(() => {
          this.loading = false
        }, 300)
        return
      }

      this.dataSource = []
      const { data: res } = await findTaskByHostId(this.projectName, this.hostId, this.currentPage, this.pageSize)
      console.log('任务数据', res.data.list)
      this.dataSource = res.data.list
      this.dataSource.forEach((item) => {
        item.hostNumber = item.host.hostNumber
        item.companyName = item.company.companyName
        item.runningState = item.state.runningState
        item.safeState = item.safeState.safeState
      })
      this.total = res.data.total
      setTimeout(() => {
        this.loading = false
      }, 300)
    },

    init() {
      this.findAllHost().then(() => {
        this.findAllProject()
      })
    },
    handleClick(row, type) {
      console.log(row, type)
      switch (type) {
        case 'subway':
          this.dialogVisibleSubway = true
          break
      }
    },

    handleClose() {
      this.dialogVisibleSubway = false
    },
    handleSizeChange(val) {
      this.pageSize = val
      console.log('this.pageSize', this.pageSize)
      this.findAllProject()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      console.log('this.currentPage', this.currentPage)
      this.findAllProject()
    },
    handleSelectItem(e) {
      console.log('选择了', e)
      // 未选择时 禁止使用按钮 并且ID为null
      if (!e) {
        this.pkTaskId = null
        this.disabled = true
        return
      }

      this.disabled = false
      // 当前工程ID
      this.pkTaskId = e.pkTaskId
    },
    // setCurrent(){
    //   setCurrent
    // }
  },
  created() {
    this.init()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.TaskJob {
  padding: 10px;
  width: calc(100% - 20px);
  .el-row {
    display: flex;
    margin-top: 10px;
    .el-input,
    .el-select {
      margin-bottom: 20px;
    }
  }
  .mr_20 {
    margin-right: 20px;
  }
  .el-input {
    width: 500px;
  }
  .el-select {
    width: 300px;
    ::v-deep .el-input__inner {
      color: #f56c6c !important;
    }
  }
}

.subway ::v-deep .el-dialog {
  width: 640px;
  height: 680px;
}

.el-message-box__message {
  height: 200px;
}
</style>
